



















































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    Settings16,
    TrashCan16,
    Edit16,
    Add16
  }
})
export default class QuestionsIndex extends Mixins(PermissionsMixin) {
  filteredData = []

  questions = []
  totalQuestions = 0

  fetchQuestions (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('question', {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.questions = this.filteredData = camelCaseKeys(response.data.data)
        this.totalQuestions = response.data.total
      })
  }

  search (searchString: string) {
    this.filteredData = this.questions.filter((question: QuestionDraft) => {
      let { code } = question

      code = code.toLowerCase()
      searchString = searchString.toLowerCase()

      if (code.includes(searchString)) {
        return question
      }
    })
  }

  onPageChange (pagination: Pagination) {
    this.fetchQuestions(pagination.page, pagination.length)
  }
}
